import googleAnalytics from './googleAnalytics';
import logOptions from './logOptions';
import ms from 'ms';

// Load dev environment
// via https://cli.vuejs.org/guide/mode-and-env.html#using-env-variables-in-client-side-code
const devConfigVarsString = process.env.VUE_APP_CONFIG_VARS;
if (devConfigVarsString) {
    // eslint-disable-next-line no-console
    console.log(
        'Reading dev env from process.env.VUE_APP_CONFIG_VARS:',
        devConfigVarsString,
    );
    const devConfigVars = JSON.parse(devConfigVarsString);
    Object.assign(window.__pwenv, devConfigVars);
}

if (process.env.VUE_APP_E2E === 'true') {
    // Satifsy pw-auth validations by specifying dummy credentials
    window.__pwenv.SSO_CLIENT_ID = 'XXX';
    window.__pwenv.SSO_CLIENT_SECRET = 'XXX';
    window.__pwenv.SSO_SERVICE_URL = 'XXX';
    window.__pwenv.SSO_ACCOUNTS_URL = 'XXX';
}

export const config = {
    mobile: false,
    sidebar: true,
    defaultLanguage: 'en_US',
    localStorage: {
        key: 'peakwork-panel-storage',
        lifetime: ms('7 days'),
    },
    permissions: {
        lifetime: ms('5 minutes'),
        requestRetryTimeout: ms('2 seconds'),
    },
    api: {
        icmBaseUrl: `${window.__pwenv.API_BASEPATH_ICM}/middleware/`,
        portfolioUrl: `${window.__pwenv.API_BASEPATH_PANEL}/ui/portfolio`,
        portfolioBaseUrl: `${window.__pwenv.API_BASEPATH_PANEL}/ui`,
        confeedUrl: `${window.__pwenv.API_BASEPATH_CONFIG}`,
        exchangeRatesUrl: `${window.__pwenv.API_BASEPATH_EXCHANGE_RATES}`,
        contentPlayerUrl: `${window.__pwenv.API_BASEPATH_CONTENT_PLAYER}`,
        contentPlayerGoalDeeplinkUrl: 'backend/contentstore/goalsearch',
        mayflyBaseUrl: '/mayfly',
        ascCentralUrl: `${window.__pwenv.API_BASEPATH_ACCOMMODATION_SALES_CONTROL_CENTRAL}`,
        ascProxyUrl: `${window.__pwenv.API_BASEPATH_ACCOMMODATION_SALES_CONTROL_PROXY}`,
        hotelOfferViewUrl: `${window.__pwenv.API_PATH_HOTEL_OFFER_VIEW}`,
        centralIcmUserSearchUrl: `${window.__pwenv.ICM_CENTRAL_USER_SEARCH_URL}`,
    },
    websocket: {
        url: `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws1`,
        reconnectInterval: 10,
        subscriptions: ['wlexport', 'wlsnapshot', 'dcassignment'],
        reconnectOnFailure: true,
    },
    resources: {
        address: 'pboxlocaladdress',
        basicConfiguration: 'pboxlocalbasicconfiguration',
        brand: 'pboxlocalbrand',
        centralSupplier: 'pboxcentralsupplier',
        contentSupplier: 'pboxlocalcontentsupplier',
        dhub: 'pboxlocaldhubconfiguration',
        dhubcancellationrules: 'pboxcancelrules',
        dhubdatamix: 'pboxlocaldhubdatamix',
        livebrandconfig: 'dorisbrandconfig',
        livesearchapi: 'dorislivesearchapienvironment',
        livesupplier: 'dorislivesearchsupplier',
        priceequalityrules: 'pboxlocalpriceequalityrules',
        pboxprrl: 'pboxprrl',
        hub: 'pboxlocalhubconfiguration',
        lpac: 'pboxlocallpacconfiguration',
        payment: 'pboxlocalpayment',
        paymentTemplate: 'pboxlocalpaymenttemplate',
        player: 'pboxlocalplayerconfiguration',
        resource: 'resource',
        supplier: 'pboxlocalsupplier',
        supplierBrandConfiguration: 'pboxlocalsuppliertobrandconfig',
        supplierType: 'pboxcentralsuppliertype',
        thirdpartysupplier: 'pboxlocaltpsupplier',
        thirdpartySupplierBrandConfiguration: 'pboxlocaltpsbrandconfig',
        brandbhubcredentials: 'pboxbrandbhubcredentials',
        pboxdistributionchannel: 'pboxdistributionchannel',
        pboxagency: 'pboxagency',
        pboxagencynumber: 'pboxagencynumber',
        pboxlocalportfolioconfiguration: 'pboxlocalportfolioconfiguration',
    },
    portfolio: {
        hotelMasterCodeSchemaLength: {
            min: 6,
            max: 14,
        },
        usageTypes: {
            hotel: 'HotelOnly',
            flight: 'FlightOnly',
        },
    },
    orchestration: {
        baseUrl: `${window.__pwenv.API_BASEPATH_ICM}/orchestration/`,
        environment: {
            search: 'environment/search',
            activate: 'environment/activate/{stackId}',
            deactivate: 'environment/deactivate/{stackId}',
            status: 'environment/status/{stackId}',
            create: 'environment/create/{stackName}',
        },
    },
    googleAnalytics,
    logOptions,
    ssoParams: {
        clientId: window.__pwenv.SSO_CLIENT_ID,
        clientSecret: window.__pwenv.SSO_CLIENT_SECRET,
        serviceUrl: window.__pwenv.SSO_SERVICE_URL,
        accountsUrl: window.__pwenv.SSO_ACCOUNTS_URL,
    },
    features: {
        allotmentsView: window.__pwenv.ENABLE_ALLOTMENTS_VIEW ?? false,
        appendCustomRateDescriptionsFeatureEnabled: window.__pwenv.ENABLE_APPENDING_CUSTOM_RATE_DESCRIPTIONS ?? false,
        contractGrouping: window.__pwenv.ENABLE_CONTRACT_GROUPING ?? false,
        customRateBrandFilter: window.__pwenv.ENABLE_CUSTOM_RATE_BRAND_FILTER ?? false,
        dhubDatamix: window.__pwenv.BOX_DATAMIX_SUPPORT ?? false,
        globalTypesCustomRates: window.__pwenv.ENABLE_GLOBAL_TYPES_CUSTOM_RATES ?? false,
        hotelAnnotations: window.__pwenv.HOTEL_ANNOTATIONS_FEATURE_FLAG ?? false,
        hybrid: window.__pwenv.ENABLE_HYBRID ?? false,
        lookerDashboards: window.__pwenv.ENABLE_LOOKER_DASHBOARDS ?? false,
        preselectForCache: window.__pwenv.ENABLE_PRESELECT_FOR_CACHE ?? false,
        productionSpecification: window.__pwenv.ENABLE_PRODUCTION_SPECIFICATION ?? false,
        unknownRooms: window.__pwenv.ENABLE_UNKNOWN_ROOMS ?? false,
        useMasterCodesToggle: window.__pwenv.ENABLE_USE_MASTER_CODES_TOGGLE ?? false,
        usernames: window.__pwenv.ENABLE_USERNAMES ?? false,
        vervotechMatching: window.__pwenv.ENABLE_VERVOTECH_MATCHING ?? false,
    },
};

export default config;
